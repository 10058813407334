import React from 'react'

const JRLogo = ({ color, size }) => (
	<svg
		width={size ? size * 2.4305555556 : 175}
		height={size ? size : 72}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 317.78 130.13"
		aria-hidden="true"
	>
		<path
			d="M225.8,228.91q3.09-6.58,6.13-12.71t5.86-11.27l-2.46-1.23-1.92,2.82c-.67.84-1.36,1.67-2.08,2.47a54.15,54.15,0,0,1-26.41,16c-13.52,3.49-28,1.58-41.95-.27l-4.21-.55c-15-1.93-32.27-3.4-47.36,3.66-7.66,3.58-14.08,9.06-20.29,14.35L93.45,245c6-5.14,12.26-10.46,19.47-13.83,14.22-6.65,30.88-5.21,45.38-3.35l4.2.55c14.32,1.89,29.13,3.85,43.32.19a57.31,57.31,0,0,0,19.09-9c-1.55,2.47-3,4.81-4.32,7q-2.86,4.78-5.15,8.95t-4.38,8.3q-2.1,4.13-4.13,8.4t-4.42,9.21a148.09,148.09,0,0,0-18.37,10.32,83.72,83.72,0,0,0-13.62,11.09A46,46,0,0,0,162,294.42a26.84,26.84,0,0,0-2.93,12,10.28,10.28,0,0,0,.68,3.95,7.2,7.2,0,0,0,1.85,2.65,8.2,8.2,0,0,0,2.57,1.52,8.62,8.62,0,0,0,2.94.51,16.47,16.47,0,0,0,8.19-2.58,45.48,45.48,0,0,0,8.94-7A82.23,82.23,0,0,0,193.16,295a106.32,106.32,0,0,0,8-12.9q2.1-4.07,4.49-9.09t5-10.76q7.19-4.29,12.94-8.34A115.81,115.81,0,0,0,234,245.73a85.83,85.83,0,0,0,8.37-8.52,96.28,96.28,0,0,0,7-9.31l-2.61-2q-2.33,3.63-5.22,7.32a70.39,70.39,0,0,1-6.81,7.43,90.45,90.45,0,0,1-9.09,7.5,116.38,116.38,0,0,1-12,7.53q3-6.53,6-13.33T225.8,228.91Zm-35.65,57.28a137.2,137.2,0,0,1-7.89,13.37,41.92,41.92,0,0,1-7.47,8.62,11.58,11.58,0,0,1-7.42,3.05,3.77,3.77,0,0,1-3.16-1.27,5,5,0,0,1-1-3.15,18.3,18.3,0,0,1,2.24-7.68,48.45,48.45,0,0,1,6.45-9.57,78.13,78.13,0,0,1,10.25-10,84.6,84.6,0,0,1,13.59-9l3.12-1.67Q194.24,278.4,190.15,286.19Z"
			transform="translate(-91.11 -184.93)"
			fill={color || '#000'}
		/>
		<path
			d="M240.3,197.25a5.37,5.37,0,0,0,3.26-1.38,15.77,15.77,0,0,0,3.19-3.47,6.94,6.94,0,0,0,1.38-3.84,3.65,3.65,0,0,0-1-2.47,3.44,3.44,0,0,0-2.65-1.16,4,4,0,0,0-2.61,1,10,10,0,0,0-2.13,2.46,13.33,13.33,0,0,0-1.42,3,9.3,9.3,0,0,0-.5,2.76,3.55,3.55,0,0,0,.65,2.14A2.17,2.17,0,0,0,240.3,197.25Z"
			transform="translate(-91.11 -184.93)"
			fill={color || '#000'}
		/>
		<path
			d="M408.42,230.09c-16.25,2.12-35,3.18-50.65-4.94-2.69-1.4-5.32-3.09-7.86-4.73-1.86-1.19-3.77-2.43-5.73-3.56a72.45,72.45,0,0,0-13.51-6.06c-6.84-2.26-19.82-5.05-30,1.64-5.56,3.67-9.08,9.4-11.78,14.51h0c-.7,1.1-1.35,2.37-2.18,3.71q-1.67,2.72-3.62,5.69c-1.31,2-2.67,3.93-4.1,5.83a43.34,43.34,0,0,1-4.42,5.11A24.74,24.74,0,0,1,270,251a9.18,9.18,0,0,1-4.75,1.41,7.56,7.56,0,0,1-1.48-.14,3.91,3.91,0,0,1-1.42-.58,3.11,3.11,0,0,1-1.05-1.27,5.23,5.23,0,0,1-.4-2.21,15,15,0,0,1,.51-3.77,28.7,28.7,0,0,1,1.34-3.91c.56-1.3,1.18-2.6,1.88-3.88s1.42-2.54,2.14-3.8q1.81-3.12,3-5.47c.8-1.57,1.42-2.94,1.88-4.09a21.22,21.22,0,0,0,1-3,9.59,9.59,0,0,0,.29-2.1,4.56,4.56,0,0,0-.18-1.41,4.34,4.34,0,0,0-.94-1.38,29.61,29.61,0,0,0-2.43-2.24q-1.67-1.41-4.56-4a3,3,0,0,1-1.05-1.42,4.8,4.8,0,0,1-.26-1.55,6.09,6.09,0,0,1,.33-2c.22-.63.37-1.09.47-1.38l-2.1-1.09-15.51,24.28,2.61,2c1.21-2,2.46-4.09,3.77-6.19s2.51-4,3.62-5.69c1.26-2,2.49-3.93,3.7-5.87A6.43,6.43,0,0,0,262,212l1.88,1.45a10,10,0,0,1,1.52,1.41,2.52,2.52,0,0,1,.62,1.67,7.48,7.48,0,0,1-.87,3.12,52.48,52.48,0,0,1-2.76,4.81c-1.25,2-2.51,4.22-3.76,6.63a62.7,62.7,0,0,0-3.3,7.5,21.74,21.74,0,0,0-1.41,7.43,11.8,11.8,0,0,0,.79,4.53,9.35,9.35,0,0,0,2.18,3.22,8.6,8.6,0,0,0,3.19,1.92,11.8,11.8,0,0,0,3.84.62,15.06,15.06,0,0,0,6.95-1.67,26.27,26.27,0,0,0,6.05-4.27,40.61,40.61,0,0,0,5.15-5.87c1.57-2.18,3-4.31,4.31-6.41s2.45-4.06,3.44-5.87c.81-1.49,1.64-2.64,2.28-3.62h0c2.49-4.72,5.7-10,10.59-13.19,8.87-5.85,20.63-3.29,26.85-1.23A67.84,67.84,0,0,1,342.38,220c1.88,1.09,3.76,2.3,5.58,3.47,2.61,1.68,5.31,3.42,8.16,4.9,10.05,5.21,21.16,6.86,32.11,6.86a160.54,160.54,0,0,0,20.66-1.55Z"
			transform="translate(-91.11 -184.93)"
			fill={color || '#000'}
		/>
	</svg>
)

export default JRLogo
